import React, { useEffect } from "react";

import { Paper } from "@mui/material";

import { playAudioFromBase64 } from "../../utils/speak";

// const pageText =
// 	"Sorry, this browser is not supported. At the moment, the Multiverse is only open on Google Chrome with desktop or laptop computers. Please go to your nearest laptop computer or click the link to download the latest version of Chrome for Desktop.";
const UnsupportedBrowser = ({}) => {
	// useEffect(() => {
	// 	playAudioFromBase64(pageText).catch((e) => {
	// 		return;
	// 	});
	// }, []);
	return (
		<div
			style={{
				background: "url(/assets/background.jpg)",
				position: "fixed",
				height: "100%",
				width: "100%",
				top: "0",
				left: "0",
				display: "grid",
				placeItems: "center",
			}}
		>
			<Paper
				style={{
					color: "white",
					background: "gray",
					padding: "36px",
					maxWidth: "50%",
					textAlign: "center",
					position: "relative",
				}}
			>
				<div>
					Sorry, this browser is not supported. At the moment, the
					Multiverse is only open on Google Chrome with desktop or
					laptop computers. Please go to your nearest laptop computer
					or click{" "}
					<a
						href="https://www.google.com/intl/en_in/chrome/"
						style={{ color: "white" }}
					>
						here
					</a>{" "}
					to download the latest version of Chrome for Desktop.
				</div>
				<div className="left-point" />
				<div className="carla-box">
					<img src="/assets/carla.gif" alt="carla" />
				</div>
			</Paper>
		</div>
	);
};

export default UnsupportedBrowser;
