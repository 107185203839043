import React, { useState, useEffect } from "react";

import { Paper } from "@mui/material";
import { useHistory } from "react-router-dom";

import "./InvalidPage.css";

import Loading from "../Loading/Loading.jsx";

const InvalidPage = (props) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [startDateTime, setStartDateTime] = useState(null);
	const [endDateTime, setEndDateTime] = useState(null);
	const history = useHistory();

	useEffect(() => {
		const options = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		if (props.location.state.startTime) {
			let propsDate = new Date(
				Date.parse(props.location.state.startTime)
			);
			if (props.location.state.invalidType === "early") {
				redirectSession();
			}

			setStartDate(propsDate.toLocaleDateString(undefined, options));
			setStartDateTime(
				propsDate.toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				})
			);
		}
		if (props.location.state.endTime) {
			let endPropsDate = new Date(
				Date.parse(props.location.state.endTime)
			);
			setEndDate(endPropsDate.toLocaleDateString(undefined, options));
			setEndDateTime(
				endPropsDate.toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				})
			);
		}
	}, []);
	// console.log(props.message);
	const redirectSession = () => {
		const checkTime = setInterval(() => {
			if (new Date() >= new Date(props.location.state.startTime)) {
				clearInterval(checkTime);
				let sId = localStorage.getItem("sessionId");
				history.push(`/joinsession/${sId}`);
			}
		}, 1000);
	};
	return (
		<>
			{ <video loop muted disablePictureInPicture autoPlay id="background-stars">
				<source src="/assets/background-stars.mp4" type="video/mp4"/>
			</video> }

			<div className="invalid-container">
				<Paper
					style={{
						color: "white",
						backgroundColor: "grey",
						padding: "36px",
						position: "relative",
						marginBottom: "10%",
						zIndex: 2,
					}}
				>
					<div
						style={{
							fontSize: "48px",
							fontWeight: "bold",
							textAlign: "center",
						}}
					>
						Errors Encountered
					</div>

					<div
						style={{
							fontSize: "24px",
							textAlign: "center",
						}}
					>
						{props.location.state.invalidType === "early" &&
							(startDateTime ? (
								<>
									{" "}
									<div>
										{(new Date(
											props.location.state.startTime
										) -
											new Date()) /
											6e4 >
										5 ? (
											<div>
												<div>
													Did you find a wormhole?
												</div>
												<div>
													We will begin our journey to
													the Multiverse on{" "}
													{startDate} at{" "}
													{startDateTime}...
												</div>
											</div>
										) : (
											<div>
												We will begin our journey to the
												Multiverse shortly..
											</div>
										)}
									</div>
								</>
							) : (
								<Loading />
							))}
						{props.location.state.invalidType === "late" ||
						props.location.state.invalidType === "invalid" ? (
							<>
								{props.location.state.invalidType ===
									"late" && (
									<>
										<div>
											Sorry, but the portals to the
											Multiverse closed on {endDate} at{" "}
											{endDateTime}...
										</div>
										<div>
											Check your email for updates on all
											upcoming sessions.
										</div>
									</>
								)}
								{props.location.state.invalidType ===
									"invalid" && (
									<div>{props.location.state.msg}</div>
								)}
							</>
						) : (
							<div>Please login with the provided link!</div>
						)}
						<div>
							If you need help, contact us via chat in the bottom
							right corner.
						</div>
					</div>
					<div className="left-point" />
					<div className="carla-box">
						<img src="/assets/carla.gif" alt="carla" />
					</div>
				</Paper>
			</div>
		</>
	);
};

export default InvalidPage;
