import React, { useState, useEffect } from "react";

import axios from "axios";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";

import { useSession } from "../../context/SessionContext";

const SessionJoiningPage = (props) => {
	const history = useHistory();
	const sessionId = props.match.params.sessionId;
	const token = localStorage.getItem("access-token");
	const { updateSessionId, updatePreviousSessionId, updateNextSessionId } = useSession();
		useEffect(() => {
		const socket = io(process.env.REACT_APP_SOCKET_BASE, {
			path: process.env.REACT_APP_SOCKET_NAMESPACE,
		});
		socket.on("connect", () => {


    		
			console.log("connected to socket!");
			
			socket.emit("capture-ip", { sessionId });

			if (token) {
				updateSessionId({ id: sessionId });

				let init = async () => {
					try {
						const response = await axios.get(
							`${process.env.REACT_APP_BASE_SERVER_URL}/api/dashboard/checksession`,
							{
								params: { sessionId },
								headers: {
									Authorization: token,
								},
							}
						);

						console.log("cool ", response.data);

						let previousSessionId = response.data.data.previousSessionId;
						updatePreviousSessionId({ id: previousSessionId });			

						let nextSessionId = response.data.data.nextSessionId;
						updateNextSessionId({ id: nextSessionId });

						if (response.data.msg === "running_session") {
							history.push("/dashboard");
						} else if (response.data.msg === "not_started") {
							history.push({
								pathname: "/invalid",
								state: {
									invalidType: "early",
									msg: "Session will start on ",
									startTime: response.data.data.start,
									endTime: response.data.data.end,
								},
							});
						} else if (response.data.msg === "ended") {
							history.push({
								pathname: "/invalid",
								state: {
									invalidType: "late",
									msg: "Session Ended",
									endTime: response.data.data.end,
								},
							});
						} else if (!response.data.success) {
							history.push({
								pathname: "/invalid",
								state: {
									invalidType: "invalid",
									msg: "Failed to load specified session.",
								},
							});
						}
					} catch (err) {
						console.log(err);
						if(err.response.status === 401) {
							history.push({ pathname: "/", state: { sessionId } });
						}
					}
				};
				init();
			} else {
				history.push({ pathname: "/", state: { sessionId } });
			}
		});

		return () => {
			console.log("socket disconnected");
			socket.disconnect();
		};
	}, []);

	return (
		<div>
			<div className="session-joining-page">
				Checking in with Carla…
			</div>
		</div>
	);
};

export default SessionJoiningPage;
