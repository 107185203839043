import React from "react";

import { Route, Redirect } from "react-router-dom";
const PrivateRoute = ({ component: Component, ...rest }) => {
	let token = localStorage.getItem("access-token");
	return (
		<Route
			{...rest}
			render={(props) => {
				return token ? <Component {...props} /> : <Redirect to="/" />;
			}}
		/>
	);
};

export default PrivateRoute;
