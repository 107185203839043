import { createTheme as createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#888888",
			main: "#999999",
			dark: "#AAAAAA",
		},
		secondary: {
			light: "#EEEEEE",
			main: "#F8F9FB",
			dark: "#FBFBFB",
		},
	},
});

export default theme;
