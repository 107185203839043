import React, { useEffect, useState, useRef } from "react";

import "./FeedbackScreens.css";
import { Button, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Rating, TextField } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { useSession } from "../../context/SessionContext";
import { playAudioFromBase64 } from "../../utils/speak";

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "darkgray",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
			{
				borderColor: "white",
			},
		"& .MuiOutlinedInput-input": {
			color: "white",
		},
		"&:hover .MuiOutlinedInput-input": {
			color: "darkgray",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiInputLabel-outlined": {
			color: "white",
		},
		"&:hover .MuiInputLabel-outlined": {
			color: "darkgray",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "white",
		},
	},
});

export default function FeedbackScreens() {
	const [idx, setIdx] = useState(0);
	const [rating, setRating] = useState(null);
	const [text, setText] = useState();
	const [choice, setChoice] = useState("no");
	const { matchedUser, sessionId } = useSession();
	const nextSessionId = localStorage.getItem("nextSessionId");
	const [countdownTime, setCountdownTime] = useState(4);

	useEffect(() => {
		if (!process.env.REACT_APP_DISABLE_RECORDING) {
			axios
				.post(
					`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/stoprecording`,
					{
						sessionId: localStorage.getItem("sessionId"),
						roomId: localStorage.getItem("roomId"),
					},
					{
						headers: {
							Authorization: localStorage.getItem("access-token"),
						},
					}
				)
				.then((res) => {
					console.log(res);
				}).catch((err) => {console.log(err);});
			console.log("stopping recording");
		}
	}, []);

	useEffect(() => {
		const token = localStorage.getItem("access-token");
		axios.post(
			`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/postfeedback`,
			{
				sessionId,
				rating,
				text,
				meet: choice,
			},
			{
				headers: {
					Authorization: token,
				},
			}
		);
		console.log("Feedback Submitted");
	}, [rating, text, choice]);

	console.log("nextSessionId", nextSessionId);
	const [showVideo, setShowVideo] = useState(!(nextSessionId?.length>0));
	const history = useHistory();
	useEffect(() => {
		if(nextSessionId?.length > 0) {
			playAudioFromBase64(`Great job. You’re  a star! Literally.
				I will email you later with info about the person you connected with...
				Let’s explore the next portal.`).then(() => {

				const checkTime = setInterval(() => {
					setCountdownTime((curr) => {
						let count = curr - 1;
						if(count == 0) {
							clearInterval(checkTime);
							history.push(`/joinSession/${nextSessionId}`);
						}
						return count;
					})
				}, 1000);
			});
		}
		else {
				setTimeout(() => {
					setShowVideo(false);
					playAudioFromBase64(
						`You have closed all of the CoachHub portals!
							I will email you later with info about the people you connected with.
							All right! Be well my friend. Hope to see you back in the Multiverse soon!`
					);
			}, 5000);
		}
	}, []);


	return (
		<div className="feedback-page-container">
				{showVideo && (
				<div
					style={{
						position: "fixed",
						zIndex: "1000",
						height: "100%",
						width: "100%",
						top: "0",
						left: "0",
						display: "grid",
						placeItems: "center",
					}}
				>
					<video
						loop
						muted
						disablePictureInPicture
						autoPlay
						style={{
							height: "100%",
							width: "100%",
							backgroundColor: "black",
						}}
					>
						<source
							src="/assets/background-stars.mp4"
							type="video/mp4"
						/>
					</video>
					<h1
						style={{
							position: "absolute",
							left: "50%",
							top: "50%",
							transform: "translate(-50%, -50%)",
							textAlign: "center",
							color: "white",
						}}
					>
						Returning to earth <br /> momentarily...
					</h1>
				</div>
			)}

			<Paper className="feedback-screen">
				<div className="feedback-content">
				{(nextSessionId?.length > 0) && (<div>Great job, you’re  a star! Literally.<br/><br/>I will email you later with info about the person you connected with.<div className="feedback-content"><img src="/assets/roundportal.gif" /></div><br/><div align="center">Let’s explore <a href={`/joinSession/${nextSessionId}`}>the next portal</a>.</div></div>)}
				{(countdownTime < 4) && (<div className="countdown">Jumping in {countdownTime}</div>)}
				{(!nextSessionId?.length > 0) && (<div>You have closed all of the CoachHub portals!<br/><br/>I will email you later with info about the people you connected with.<br/><br/>All right! Be well, my friend. Hope to see you back in the Multiverse soon!<br /></div>)}
				</div>
				<div className="left-point" />
				<div className="carla-box">
					<img src="/assets/carla.gif" alt="carla" />
				</div>
			</Paper>
		</div>
	);
}

function FeedbackRating({ rating, setRating, number, text, setText }) {
	useEffect(() => {
		playAudioFromBase64("What level of connection are you feeling right now?");
	}, []);

	const classes = useStyles();
	return (
		<div>
			<div>{number}. What level of connection are you feeling right now?</div>
			<div className="feedback-rating-stars">
				<Rating
					size="large"
					name="simple-controlled"
					value={rating}
					onChange={(event, newValue) => {
						setRating(newValue);
					}}
				/>
			</div>
			<div className="feedback-text">
				<TextField
					className={classes.root}
					variant="outlined"
					id="outlined-multiline-static"
					rows={4}
					label={
						rating <= 3
							? "What was missing for you?"
							: "What was most connecting for you?"
					}
					multiline
					fullWidth
					placeholder="Please share what drove your choice…"
					onChange={(event) => {
						setText(event.target.value);
					}}
				/>
			</div>
		</div>
	);
}

function YesNo({ choice, setChoice, number, partnerName }) {
	useEffect(() => {
		playAudioFromBase64(
			`Would you like to reveal your earth identity to ${
				partnerName ? partnerName : "your partner"
			}?`
		);
	}, []);

	return (
		<div>
			<div>
				{number}. Would you like to reveal your earth identity to {" "}
				{partnerName ? partnerName : "your partner"}?
			</div>
			<div className="feedback-rating-stars">
			</div>
		</div>
	);
}
function NextSession() {
}
function TheEnd() {
}