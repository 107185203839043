import React, { useEffect, useMemo } from "react";

// imports
import { Provider, ErrorBoundary } from "@rollbar/react";
import axios from "axios";
import { isMobile, isChrome } from "react-device-detect";
import { Route, Switch, useLocation } from "react-router-dom";

import Loading from "../src/components/Loading/Loading";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import FeedbackScreens from "./components/FeedbackScreens/FeedbackScreens";
import Homepage from "./components/Homepage/Homepage.jsx";
import InvalidPage from "./components/InvalidPage/InvalidPage.jsx";
import "./App.css";
import RegisterForm from "./components/RegisterForm/RegisterForm.jsx";
import RoomPage from "./components/RoomPage/RoomPage.jsx";
import SessionJoiningPage from "./components/SessionJoiningPage/SessionJoiningPage.jsx";
import UnsupportedBrowser from "./components/UnsupportedBrowser/UnsupportedBrowser.jsx";
import WaitingPage from "./components/WaitingPage/WaitingPage.jsx";
import { useSession } from "./context/SessionContext";
import PrivateRoute from "./PrivateRoute/PrivateRoute.jsx";

const rollbarConfig = {
	accessToken: "6249b43fb7db4946b89e38a194c16507",
	captureUncaught: true,
	captureUnhandledRejections: true,
	payload: {
		environment: process.env.NODE_ENV,
	},
};

export const serverLog = async (sessionId,activityType, description) => {
	axios.post(
		`${process.env.REACT_APP_BASE_SERVER_URL}/api/dashboard/log`,
		{
			sessionId: sessionId,
			description: description,
			activityType: activityType
		},
		{
			headers: {
				Authorization: localStorage.getItem("access-token"),
			},
		}
	)
	.then((res) => {
		console.log(res);
	})
	.catch(function (err) {
		console.log(err);
	});
}

function App() {
	const { sessionId, setSessionId } = useSession();
	let location = useLocation();
	// const { location } = useLocation();
	useEffect(() => {
		if (process.env.REACT_APP_DISABLE_RECORDING) {
			console.log("recording is disabled");
		}

		window.onunhandledrejection = function (
			message,
			file,
			line,
			column,
			errorObject
		) {
			errorLogger(message, file, line, column, errorObject);
		};

		window.onerror = function (message, file, line, column, errorObject) {
			errorLogger(message, file, line, column, errorObject);
		};
	}, []);

	const backgroundimg = useMemo(() => {
		let page = location.pathname?.split("/")[1];
		switch (page) {
			case "dashboard":
			case "room-feedback":
				return `background.jpg`;
			default:
				return `none.jpg`; // not ideal, but better than flashing background.jpg before the video. poster for video doesn't tile well
		}
	}, [location]);

	const errorLogger = async (message, file, line, column, errorObject) => {
		column = column || (window.event && window.event.errorCharacter);
		var stack = errorObject ? errorObject.stack : null;

		// if (!stack) {
		// 	var stack = [];
		// 	var f = arguments.callee.caller;
		// 	while (f) {
		// 		stack.push(f.name);
		// 		f = f.caller;
		// 	}
		// 	errorObject["stack"] = stack;
		// }

		var data = {
			message: message,
			file: file,
			line: line,
			column: column,
			errorStack: stack,
		};
		setSessionId((curr) => {
			console.log({
				sessionId: curr,
				description: data.message.reason?.stack || data.message,
				activityType: "react_error",
			});
			serverLog(curr, "react_error", data.message.reason?.stack || data.message);

			return curr;
		});
		return false;
	};

	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary>
				<div
					className="App"
					style={{
						backgroundImage: `url(/assets/${backgroundimg})`,
					}}
				>
					{isChrome && !isMobile ? (
						<Switch>
							<Route exact path="/" component={Homepage} />
							<PrivateRoute
								exact
								path="/register"
								component={RegisterForm}
							/>
							<Route
								exact
								path="/joinsession/:sessionId"
								component={SessionJoiningPage}
							/>
							<PrivateRoute
								exact
								path="/dashboard"
								component={sessionId ? Dashboard : Loading}
							/>
							<PrivateRoute
								exact
								path="/room-waiting"
								component={sessionId ? WaitingPage : Loading}
							/>
							<PrivateRoute
								exact
								path="/room/:roomId"
								component={sessionId ? RoomPage : Loading}
							/>
							<PrivateRoute
								exact
								path="/room-feedback"
								component={FeedbackScreens}
							/>

							<PrivateRoute
								exact
								path="/dashboardnew"
								component={sessionId ? Dashboard : Loading}
							/>
							<PrivateRoute
								exact
								path="/invalid"
								component={InvalidPage}
							/>
						</Switch>
					) : (
						<UnsupportedBrowser />
					)}
				</div>
			</ErrorBoundary>
		</Provider>
	);
}

export default App;
