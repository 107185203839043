import React, { useState, useEffect } from "react";

import {
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	MenuItem,
	Select,
	IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

const MicDeviceSelection = ({
	open,
	closeDialog: handleClose,
	selectedDevice,
	setSelectedDevice,
}) => {
	const [deviceList, setDeviceList] = useState([]);

	useEffect(() => {
		if (
			!navigator.mediaDevices ||
			!navigator.mediaDevices.enumerateDevices
		) {
			console.log("enumerateDevices() not supported.");
		} else if (!deviceList || deviceList.length === 0) {
			navigator.mediaDevices
				.enumerateDevices()
				.then((devices) => {
					setDeviceList(
						devices.filter((device) => device.kind === "audioinput")
					);
				})
				.catch(function (err) {
					console.log(err.name + ": " + err.message);
				});
		}
	}, []);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent style={{ backgroundColor: "gray", color: "white" }}>
				<DialogContentText id="alert-dialog-description">
					<div style={{ color: "white" }}>Select microphone:</div>
					{/*microphone menu*/}
					<br />
					{
						<div className="pre-room-audio-test-voice-select">
							<div className="options">
								<Select
									color="secondary"
									variant="outlined"
									value={selectedDevice}
									style={{
										color: "white",
									}}
									onChange={(e) => {
										setSelectedDevice(e.target.value);
										localStorage.setItem(
											"audio-device",
											e.target.value
										);
									}}
								>
									{deviceList.map((device) => (
										<MenuItem
											value={device.deviceId}
											key={device.deviceId}
										>
											{device.label}
										</MenuItem>
									))}
								</Select>
							</div>
						</div>
					}
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ backgroundColor: "gray", color: "white" }}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
					}}
				>
					<Button
						variant="contained"
						onClick={handleClose}
						color="secondary"
					>
						Done
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default MicDeviceSelection;
