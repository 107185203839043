import React from "react";

import "./screens.css";

const GenericModule = ({ payload }) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
				width: "100%",
				flexDirection: "column",
				fontSize: "24px",
			}}
		>
			<div
				className="dangerous-div"
				dangerouslySetInnerHTML={{ __html: payload?.text }}
			></div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "row",
					flexWrap: "wrap",
				}}
			>
				{payload?.images &&
					payload?.images.map((image) => {
						return (
							<img
								style={{
									minWidth: "100px",
									minHeight: "100px",
								}}
								src={image.link}
								key={image.key}
							></img>
						);
					})}
			</div>
		</div>
	);
};

export default GenericModule;
