import React, { useEffect, useState, useRef } from "react";

import { Button } from "@material-ui/core";

import "./Recorder.css";

function supportsRecording(mimeType) {
	if (!window.MediaRecorder) return false;
	if (!MediaRecorder.isTypeSupported)
		return (
			mimeType.startsWith("audio/mp4") || mimeType.startsWith("video/mp4")
		);
	return MediaRecorder.isTypeSupported(mimeType);
}

const Recorder = ({ stream, isReadyToGo, isRecording, setIsRecording }) => {
	const [mediaRecorder, setMediaRecorder] = useState(null);

	let chunks = [];

	useEffect(() => {
		const mRecorder = new MediaRecorder(stream.stream);
		setMediaRecorder(mRecorder);

		return () => {
			stream.stream.getAudioTracks().forEach(function (track) {
				track.stop();
			});
		};
	}, [stream]);

	const startRecording = () => {
		mediaRecorder.onstop = function (e) {
			const audioObjectURL = URL.createObjectURL(
				new Blob(chunks, { type: mediaRecorder.mimeType })
			);
			chunks = [];

			// Insert audio into the DOM (Safari Compatibility)
			// Safari does not support changing src programmatically
			const audioElement = document.createElement("audio");
			audioElement.setAttribute("id", "testRecording");
			audioElement.setAttribute("controls", true);
			document.getElementById("recorder").appendChild(audioElement);
			const sourceElement = document.createElement("source");
			audioElement.appendChild(sourceElement);
			sourceElement.src = audioObjectURL;
			sourceElement.type = mediaRecorder.mimeType;

			audioElement.play();
		};
		mediaRecorder.ondataavailable = function (e) {
			chunks.push(e.data);
		};

		document.getElementById("testRecording")?.remove();
		mediaRecorder.start(1000);
		setIsRecording(true);
	};
	const stopRecording = () => {
		mediaRecorder?.stop();
		setIsRecording(false);
		isReadyToGo(true);
	};

	return (
		<div className="recorder-container">
			{(
				MediaRecorder
					? MediaRecorder.isTypeSupported
						? MediaRecorder.isTypeSupported("audio/webm")
						: false
					: false
			) ? (
				<div className="recorder" id="recorder">
					<div className="btn-container">
						<div className="record-btn">
							<Button
								variant="contained"
								onClick={() => startRecording()}
								style={
									isRecording
										? {
												backgroundColor: "green",
												color: "white",
										  }
										: {}
								}
								disabled={isRecording}
							>
								{isRecording ? "Recording" : "Record"}
							</Button>
						</div>
						<div className="stop-btn">
							<Button
								variant="contained"
								onClick={() => stopRecording()}
								disabled={!isRecording}
							>
								Stop
							</Button>
						</div>
					</div>
				</div>
			) : (
				<>
					<br></br>
					<div style={{ fontWeight: "bold", textAlign: "center" }}>
						Your browser does not support mediaRecorder.
					</div>
					<br></br>
				</>
			)}
		</div>
	);
};

export default Recorder;
