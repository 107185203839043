import React, { useState } from "react";

import { Button, styled } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@mui/material";
import "./RegisterForm.css";
import { useHistory } from "react-router";

import { useAuth } from "../../context/AuthContext";

const RegisterForm = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const { register } = useAuth();
	let history = useHistory();

	const handleRegister = async () => {
		await register({
			email,
			password,
			confirmPassword,
			firstName,
			lastName,
		});
		setFirstName("");
		setLastName("");
		setEmail("");
		setPassword("");
		setConfirmPassword("");
		history.push("/");
	};
	return (
		<Paper className="register-form">
			<div className="form-title">
				<h2>Register</h2>
			</div>
			<div className="form-fields">
				<CustomTextField
					variant="outlined"
					color="secondary"
					placeholder="First Name"
					value={firstName}
					onChange={(e) => {
						setFirstName(e.target.value);
					}}
				/>
			</div>
			<div className="form-fields ">
				<CustomTextField
					color="secondary"
					placeholder="Last Name"
					variant="outlined"
					value={lastName}
					onChange={(e) => {
						setLastName(e.target.value);
					}}
					required
				/>
			</div>
			<div className="form-fields ">
				<CustomTextField
					color="secondary"
					placeholder="Email"
					variant="outlined"
					type="email"
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				/>
			</div>
			<div className="form-fields ">
				<CustomTextField
					color="secondary"
					placeholder="Password"
					variant="outlined"
					type="password"
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
					}}
				/>
			</div>
			<div className="form-fields ">
				<CustomTextField
					color="secondary"
					placeholder="Confirm Password"
					variant="outlined"
					type="password"
					value={confirmPassword}
					onChange={(e) => {
						setConfirmPassword(e.target.value);
					}}
				/>
			</div>
			<div className="form-fields ">
				<Button
					onClick={() => handleRegister()}
					variant="contained"
					color="secondary"
				>
					Submit
				</Button>
			</div>
			<div className="left-point" />
			<div className="carla-box">
				<img src="/assets/carla.gif" alt="carla" />
			</div>
		</Paper>
	);
};

const CustomTextField = styled(TextField)({
	"& .MuiFormLabel-root": {
		display: "none",
	},

	"& .MuiOutlinedInput-root": {
		"&": {
			background: "white",
		},
		"& fieldset": {
			borderWidth: "0",
			padding: "0",
		},
		"&:hover fieldset": {
			borderWidth: "0",
			borderColor: "transparent",
		},
		"&.Mui-focused fieldset": {
			borderWidth: "0",
			borderColor: "transparent",
		},
		"&.Mui-focused label": {
			color: "#000",
		},
	},

	"& .MuiOutlinedInput-input": {
		padding: "12px",
	},
});
export default RegisterForm;
