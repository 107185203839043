import React, { useContext, useState, useEffect } from "react";

import axios from "axios";
import PropTypes from "prop-types";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	AuthProvider.propTypes = {
		children: PropTypes.object,
	};
	const [userId, setUserId] = useState("");
	const [loading] = useState(false);

	useEffect(() => {
		setUserId(localStorage.getItem("userId"));
	}, []);

	async function register(payload) {
		const { email, password, confirmPassword, firstName, lastName } =
			payload;
		var response = await axios.post(
			`${process.env.REACT_APP_BASE_SERVER_URL}/api/user/register`,
			{ email, password, confirmPassword, firstName, lastName }
		);
		console.log(response);
	}

	async function login(payload) {
		const { email, password } = payload;
		var response = await axios.post(
			`${process.env.REACT_APP_BASE_SERVER_URL}/api/user/login`,
			{ email, password }
		);

		var token = response.data.data.token;
		setUserId(response.data.data.userId);
		localStorage.setItem("userId", response.data.data.userId);
		localStorage.setItem("access-token", `Bearer ${token}`);
	}
	async function googleLogin(payload) {
		const { email, firstName, lastName, googleId } = payload;
		var response = await axios.post(
			`${process.env.REACT_APP_BASE_SERVER_URL}/api/user/googlelogin`,
			{ email, firstName, lastName, googleId }
		);

		var token = response.data.data.token;
		setUserId(response.data.data.userId);
		localStorage.setItem("userId", response.data.data.userId);
		localStorage.setItem("access-token", `Bearer ${token}`);
	}
	const value = {
		userId,
		register,
		login,
		googleLogin,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
