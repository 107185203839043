import React from "react";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App.jsx";
import { AuthProvider } from "./context/AuthContext";
import { SessionProvider } from "./context/SessionContext";
import theme from "./MuiTheme.js";
import "./index.css";

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<AuthProvider>
			<SessionProvider>
				<Router>
					<App />
				</Router>
			</SessionProvider>
		</AuthProvider>
	</MuiThemeProvider>,
	document.getElementById("root")
);
