import React, { useState } from "react";

import {
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useSession } from "../../context/SessionContext";

const PartnerLeftDialog = ({ open, closeDialog: handleClose }) => {
	const { matchedUser } = useSession();
	const history = useHistory();
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleEnd = () => {
		history.push("/room-feedback");
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{`Your partner, ${matchedUser} has disconnected.`}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Your partner has left the session, you can wait for them to
					come back or end the session.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Wait</Button>
				<Button onClick={handleEnd}>End session</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PartnerLeftDialog;
