import React from "react";

const RolePlayModule = ({ payload }) => {
	return (
		<>
			{payload.screen ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						flexDirection: "column",
						fontSize: "24px",
					}}
				>
					<div>{payload.screen.text}</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
							flexWrap: "wrap",
						}}
					>
						{payload.images &&
							payload.images.map((image) => {
								return (
									<img
										style={{
											minWidth: "100px",
											minHeight: "100px",
										}}
										src={image.link}
										key={image.key}
									></img>
								);
							})}
					</div>
				</div>
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						flexDirection: "column",
						fontSize: "24px",
					}}
				>
					<div>
						<b>Scenario: </b>
						{payload?.character.scenario}
						<br />
						<b>Brief: </b>
						{payload?.character.brief}
					</div>
				</div>
			)}
		</>
	);
};

export default RolePlayModule;
