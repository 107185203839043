import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading() {
	return (
		<Box
			sx={{
				display: "flex",
				position: "fixed",
				left: "50%",
				top: "50%",
				transform: "translate(-50%, -50%)",
				background: "transparent",
				backgroundColor: "transparent",
			}}
		>
			<CircularProgress />
		</Box>
	);
}
