import querystring from "querystring";

import React, { useState, useEffect, useRef } from "react";

import { Button, styled } from "@material-ui/core";
import GoogleIcon from "@mui/icons-material/Google";
import { Paper } from "@mui/material";
import axios from "axios";
// import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

import "./LoginForm.css";

const LoginForm = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [changeComponent, setChangeComponent] = useState("register");
	const googleLoginBtn = useRef();
	const { login, googleLogin } = useAuth();
	const history = useHistory();

	useEffect(() => {
		if (googleLoginBtn.current) {
			google.accounts.id.initialize({
				client_id:
					// "569896488111-a6qiufi8p5j5covgj1budiln5vf68ln7.apps.googleusercontent.com", // oxy
					"834526573450-8b1f27cr5ht4istssrh8irqlaeuiggi1.apps.googleusercontent.com", // ours
				callback: handleCredentialResponse,
			});
			google.accounts.id.renderButton(googleLoginBtn.current, {
				theme: "outline",
				size: "large",
			});
			google.accounts.id.prompt();
		}
	}, [googleLoginBtn.current]);

	async function handleCredentialResponse(response) {
		const res = await axios.get(
			`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${response.credential}`
		);

		const googleData = res.data;

		await googleLogin({
			email: googleData?.email,
			firstName: googleData?.given_name,
			lastName: googleData?.family_name,
			googleId: googleData?.sub,
		});

		$crisp.push(["set", "user:email", [googleData?.email]]);
		$crisp.push(["set", "user:nickname", [googleData?.name]]);

		if (props.sId) history.push(`/joinsession/${props.sId}`);
		else {
			history.push({
				pathname: "/invalid",
				state: {
					msg: "Please login through the provided link (missing session code in URL)",
				},
			});
		}
	}

	const handleLogin = async () => {
		await login({ email, password });
		setEmail("");
		setPassword("");
		if (props.sId) history.push(`/joinsession/${props.sId}`);
		else {
			history.push({
				pathname: "/invalid",
				state: {
					msg: "Please login through the provided link (missing session code in URL)",
				},
			});
		}
	};

	return (
		<>
			{
				<video
					loop
					muted
					disablePictureInPicture
					autoPlay
					id="background-stars"
				>
					<source
						src="/assets/background-stars.mp4"
						type="video/mp4"
					/>
				</video>
			}
			<Paper className="login-form">
				<div className="left-point" />
				<div className="form-title">
					<h2>Welcome to the Multiverse!</h2>
				</div>
				<div id="buttonDiv" ref={googleLoginBtn}></div>
				<div className="privacy-text">
					By signing in with Google, you agree to our <a href="https://www.iubenda.com/terms-and-conditions/85837401">Terms</a>.
					Learn how we collect, use and share your data in our <a href="https://www.iubenda.com/privacy-policy/85837401">Data Policy</a> and how we use cookies and similar technology in our <a href="https://www.iubenda.com/privacy-policy/85837401/cookie-policy">Cookies Policy</a>.
					You may receive email from us and can opt out any time.
				</div>
				<div className="carla-box">
					<img src="/assets/carla.gif" alt="carla" />
				</div>
			</Paper>
		</>
	);
};

export default LoginForm;
