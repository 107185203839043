import React, { useEffect, useState } from "react";

import axios from "axios";

import { useSession } from "../../context/SessionContext";

const QuestionDiscussionModule = () => {
	const [baseUrl] = useState(process.env.REACT_APP_BASE_IMAGES_URL);
	const [answers, setAnswers] = useState(null);
	const { sessionId } = useSession();

	useEffect(() => {
		let token = localStorage.getItem("access-token");
		axios
			.get(
				`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getanswers`,
				{
					params: { sessionId },
					headers: { Authorization: token },
				}
			)
			.then((res) => {
				setAnswers(res.data.data);
			});
	}, []);
	return (
		<div style={{ overflowY: "auto", height: "350px", color: "black" }}>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "auto auto",
					gap: "12px",
				}}
			>
				<div className="my-answers" style={answerCompartmentStyle}>
					<div style={{ margin: "16px 0px", textAlign: "center" }}>
						You chose these answers:
					</div>
					<div style={imageSetContainerStyle}>
						{answers?.myAnswers.map((q) => (
							<div key={q.answerKey} style={imageContainerStyle}>
								<img
									style={{
										width: "100%",
										height: "100%",
									}}
									src={baseUrl + q.answerKey}
								/>
							</div>
						))}
					</div>
				</div>
				<div className="their-answers" style={answerCompartmentStyle}>
					<div style={{ margin: "16px 0px", textAlign: "center" }}>
						Your partner chose these answers:
					</div>
					<div style={imageSetContainerStyle}>
						{answers?.partnerAnswers.map((q) => (
							<div key={q.answerKey} style={imageContainerStyle}>
								<img
									style={{
										width: "100%",
										height: "100%",
									}}
									src={baseUrl + q.answerKey}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

const answerCompartmentStyle = {
	borderRadius: "16px",
	backgroundColor: "lightgray",
	padding: "8px",
};

const imageSetContainerStyle = {
	display: "flex",
	flexWrap: "wrap",
	gap: "16px",
	justifyContent: "space-evenly",
};

const imageContainerStyle = {
	position: "relative",
	width: "200px",
	height: "200px",
	overflow: "hidden",
	borderRadius: "16px",
	background: "lightgray",
};

export default QuestionDiscussionModule;
