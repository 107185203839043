import axios from "axios";
import { Base64 } from 'js-base64';

export const playAudioFromBase64 = async (sentence) => {
	var token = localStorage.getItem("access-token");

	const res = await axios.post(
		`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getaudio`,
		{ sentence },
		{
			headers: {
				Authorization: token,
			},
		}
	);

	const arr = Base64.toUint8Array(res.data);

	const blob = new Blob([arr], {
		type: "audio/mpeg",
	});

	const audioObjectURL = window.URL.createObjectURL(blob);
	
	// Insert audio into the DOM (Safari Compatibility)
	const audioElement = document.createElement('audio');
	audioElement.setAttribute("id", "speakAudio");
	audioElement.setAttribute('controls', false);
	audioElement.setAttribute('style', 'display:none');
	document.body.appendChild(audioElement);
	const sourceElement = document.createElement('source');
	audioElement.appendChild(sourceElement);
	sourceElement.src = audioObjectURL;
	sourceElement.type = 'audio/mp3';
	
	const audio = new Audio(audioObjectURL);
	return new Promise((res, rej) => {
		audio.play().catch(() => {
			setTimeout(() => {
				res();
			}, 3000);
		});
		audio.onloadedmetadata = function () {
			// duration in seconds
			//call functions here
		};
		audio.onended = function () {
			audioElement.remove();
			res();
		};
	});
};