import React, { useEffect, useState } from "react";

import axios from "axios";
import { useHistory } from "react-router";

import "./WaitingPage.css";
import { useSession } from "../../context/SessionContext";
import { playAudioFromBase64 } from "../../utils/speak";

const RoomPage = () => {
	const history = useHistory();
	const [startTime] = useState(new Date());
	const [matched, setMatched] = useState(false);
	const [matchedMessage, setMatchedMessage] = useState(
		"Okay! Let’s start. We will now launch you to your partner"
	);
	const [roomId, setRoomId] = useState(null);
	var token = localStorage.getItem("access-token");
	const [title, setTitle] = useState(
		"Waiting for your partner - it may take a moment. Take a deep breath while this cat helps out..."
	);
	const { nickname, sessionId, previousSessionId, updateMatchedUserName } = useSession();
	// const { userId } = useAuth();
	const postDuration = async (duration) => {
		let token = localStorage.getItem("access-token");

		console.log("joining lobby from session:", sessionId);
		await axios
			.post(
				`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/matchduration`,
				{
					sessionId,
					duration,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((res) => {
				console.log(res);
			})
			.catch((e) => console.log(e));
	};

	useEffect(() => {
		const init = async () => {
			var speak = title;
			if(previousSessionId?.length > 0) {
				speak = "While waiting for your partner to finish their Multiverse jump, reflect on your last connection.";
				setTitle(speak);
			}

			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/joinlobby`,
					{ sessionId },
					{
						headers: {
							Authorization: token,
						},
					}
				);
				if (response.data.success) {
					console.log(response.data.msg);
				}
			} catch (err) {
				console.log(err);
			}
			startMatchmaking();
			$crisp.push([
				"set",
				"session:data",
				[[["status", "startMatchmaking"]]],
			]);
			playAudioFromBase64(speak);
		};

		init();

		return () => {
			const duration =
				(new Date().getTime() - startTime.getTime()) / 1000;
			postDuration(duration);
			$crisp.push([
				"set",
				"session:data",
				[[["status", "matched, took: " + duration + " sec"]]],
			]);
			// console.log(duration);
		};
	}, []);

	const startMatchmaking = () => {
		var sentChatMessage = false;
		const matchmaking = setInterval(async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getmatch`,
					{
						sessionId,
					},
					{
						headers: {
							Authorization: token,
						},
					}
				);
				console.log(response.data);

				const duration =
					(new Date().getTime() - startTime.getTime()) / 1000;
				$crisp.push([
					"set",
					"session:data",
					[[["status", "matching for " + duration + " sec"]]],
				]);
				if (sentChatMessage == false && duration > 2 * 60) {
					$crisp.push([
						"do",
						"message:send",
						[
							"text",
							"Hi! I've been waiting more than 2 minutes for a partner.",
						],
					]);
					sentChatMessage = true;
				}

				if (response.data.success) {
					setRoomId(response.data.data.roomId);
					clearInterval(matchmaking);
					updateMatchedUserName({ names: response.data.data });
					let message = `Okay! Let’s start. We will now launch you to your partner, ${response.data.data.partnerName}.`;
					setMatchedMessage(message);
					setMatched(true);
				} else if (!response.data.success) {
					console.log(response.data.msg);
				}
			} catch (err) {
				console.log(err);
			}
		}, 5000);
	};

	useEffect(() => {
		if (matched) {
			setTimeout(() => {
				playAudioFromBase64(matchedMessage).then(() => {
					history.push(`/room/${roomId}`);
				});
			});
		}
	}, [matched]);

	return (
		<div className="room-container">
			{ <video
				loop
				muted
				disablePictureInPicture
				autoPlay
				id="background-stars"
			>
				<source src="/assets/background-stars.mp4" type="video/mp4" />
			</video> }

			<div className="dashboard-header">
				<div className="dashboard-display-name">
					<img src="/assets/avatar.png"></img>
					{nickname}
				</div>
			</div>
			<div className="room-page">
				{!matched ? (
					<>
						<div className="randomText">{title}</div>
						<div className="waiting">
							{previousSessionId && previousSessionId?.length > 0 ? (<img src="/assets/digest.gif" />) : (<img src="/assets/waiting.gif" />)}
						</div>
					</>
				) : (
					<div className="randomText">{matchedMessage}</div>
				)}
				<div className="left-point" />
				<div className="carla-box">
					<img src="/assets/carla.gif" alt="carla" />
				</div>
			</div>
		</div>
	);
};

export default RoomPage;
