import React, { useEffect, useRef, useState, useMemo } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Base64 } from "js-base64";
import { useHistory } from "react-router-dom";
import { io } from "socket.io-client";

import { useAuth } from "../../context/AuthContext";
import { useSession } from "../../context/SessionContext";
import AudioModule from "../ModuleScreens/AudioModule";
import ExperienceModule from "../ModuleScreens/ExperienceModule";
import GenericModule from "../ModuleScreens/GenericModule";
import QuestionDiscussionModule from "../ModuleScreens/QuestionDiscussionModule";
import RolePlayModule from "../ModuleScreens/RolePlayModule";
import StartupIdeaModule from "../ModuleScreens/StartupIdeaModule";
import TransitionModule from "../ModuleScreens/TransitionModule";
import WelcomeModule from "../ModuleScreens/WelcomeModule";
import PartnerLeftDialog from "./PartnerLeftDialog";
import "./ModulePlayer.css";

const showOnboarding = false;

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 30,
	color: "yellow",
}));

const transitionText = [
	"Cool. Thanks for sharing! Let’s move on to our next activity",
	"That was fun! Let’s keep it moving, moving.",
	"Interesting. Thanks for being open to this. Let’s transition to the next activity. ",
	"I appreciate you two! I have another activity for you. ",
	"Great. You two really are stars. Let’s move to the next activity. ",
];

const ModulePlayer = ({ leaveCall, client, setIsMuted }) => {
	const { nickname, sessionId, matchedUser } = useSession();
	const { userId } = useAuth();
	const [totalElapsedTime, setTotalElapsedTime] = useState(0);
	const [currentModule, setCurrentModule] = useState(null);
	const [currentModuleTime, setCurrentModuleTime] = useState(0);
	const [nextModuleTime, setNextModuleTime] = useState(0);
	const [modulesLoaded, setModulesLoaded] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [firstBell] = useState(new Audio("/assets/short_bell.mp3"));
	const [secondBell] = useState(new Audio("/assets/futuristic_bell.mp3"));
	const timerRef = useRef();
	const timeoutRef = useRef();
	const videoRef = useRef();
	const socketRef = useRef();
	let history = useHistory();

	useEffect(() => {
		async function init() {
			// console.log({ videoRef });
			videoRef.current?.play();
			var token = localStorage.getItem("access-token");
			const modules = await axios.get(
				`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getmodules`,
				{
					params: {
						sessionId: sessionId,
					},
					headers: {
						Authorization: token,
					},
				}
			);
			let moduledata = [];
			if (modules.data.data.modules.length > 0) {
				moduledata = modules.data.data.modules;
			}

			if(showOnboarding) {
				moduledata.push({
					type: "Welcome",
					priority: -2,
					duration: (process.env.NODE_ENV === 'development' ? 15 : 60),
				});
			}
			let answers = await axios.get(
				`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getanswers`,
				{
					params: { sessionId },
					headers: { Authorization: token },
				}
			);
			if (
				answers.data.data.myAnswers.length > 0 &&
				Object.keys(answers.data.data).length > 0
			) {
				const questionModule = moduledata.findIndex(
					(iteratingModule) => iteratingModule.type === "Questions"
				);
				if (questionModule > -1) {
					moduledata.push({
						type: "Answers",
						priority: -1,
						duration: moduledata[questionModule].duration,
						screens: moduledata[questionModule].screens,
					});
					moduledata.splice(
						moduledata.findIndex(
							(iteratingModule) =>
							iteratingModule.type === "Questions"
						),
						1
					);
				} else {
					console.log(
						"Did not find questions module in the session, how could this condition ever be hit?"
					);
					moduledata.push({
						type: "Answers",
						priority: -1,
						duration: 60,
					});
				}
			}
			// moduledata.push({
			// 	type: "End",
			// 	priority: Infinity,
			// 	duration: 0,
			// });
			moduledata.sort((a, b) => b.priority - a.priority);
			let passedTime = 2;
			for (let i = moduledata.length - 1; i >= 0; i--) {
				if (
					moduledata[i].type === "Generic" ||
					moduledata[i].type === "Audio" ||
					moduledata[i].type === "Role-play"
				) {
					moduledata[i]["startAt"] = passedTime;
					passedTime += moduledata[i].duration;
					if (i !== 0) {
						moduledata.splice(i, 0, {
							type: "Transition",
							startAt:
								moduledata[i].duration +
								moduledata[i].startAt -
								8,
							duration: 8,
						});
					}
				} else {
					moduledata[i]["startAt"] = passedTime;
					passedTime += moduledata[i].duration;
				}
			}
			console.log("Modules Arranged", [...moduledata]);
			const socket = io(process.env.REACT_APP_SOCKET_BASE, {
				path: process.env.REACT_APP_SOCKET_NAMESPACE,
			});
			socketRef.current = socket;
			socket.on("connect", () => console.log("connected to socket!"));

			socket.on("unpair", () => {
				console.log("Unpaired by admin!");
				history.push("/room-waiting");
			});
			await new Promise((res) => {
				let watcher = setInterval(() => {
					if (client.channelName) {
						res();
						clearInterval(watcher);
					}
				}, 100);
			});

			if (videoRef.current) {
				videoRef.current.onended = function () {
					setModulesLoaded(true);
					setupSocket(moduledata);
				};
			}
		}
		init();
		return () => clearInterval(timerRef.current);
	}, []);

	const setupSocket = (moduledata) => {
		const socket = socketRef.current;

		socket.emit("joinThisRoom", {
			channel: client.channelName,
			sessionId,
			userId,
		});
		socket.once("start", () => {
			console.log("STARTING SESSION");
			startModules(moduledata);
		});
		socket.on("partner-disconnect", () => {
			setShowDialog(true);
			clearInterval(timerRef.current);
			// clearInterval(timeoutRef.current);

			socket.once("partner-reconnect", () => {
				startModules(moduledata);
				setShowDialog(false);
				setTotalElapsedTime((curr) => {
					let time = curr;
					// console.log(time);
					socket.emit("current-time", { time });
					return curr;
				});
			});
		});

		// When this client learns that a partner has reconnected, it sends the current time
		// Then both clients get this socket message with a time to sync up to
		socket.on("set-time", (time) => {
			setTotalElapsedTime((curr) => {
				let lastModule;
				for (let i = moduledata.length - 1; i >= 0; i--) {
					if (moduledata[i].startAt < time) {
						lastModule = moduledata.pop();
					}
				}
				if (lastModule && lastModule.type !== "Welcome") {
					playModule(lastModule, moduledata);

					setCurrentModuleTime(lastModule.startAt);
					setNextModuleTime(lastModule.startAt + lastModule.duration);
					console.log("Current time: " + currentModuleTime);
					console.log("Next time: " + nextModuleTime);
				}
				return time;
			});
		});
	};

	const startModules = (moduledata) => {
		clearInterval(timerRef.current);
		timerRef.current = setInterval(() => {
			setTotalElapsedTime((curr) => {
				let count = curr + 1;
				if (moduledata.length > 0) {
					if (count === moduledata[moduledata.length - 1].startAt) {
						setCurrentModuleTime(
							moduledata[moduledata.length - 1].startAt
						);
						setNextModuleTime(
							moduledata[moduledata.length - 1].startAt +
								moduledata[moduledata.length - 1].duration
						);
						console.log("Current time: " + currentModuleTime);
						console.log("Next time: " + nextModuleTime);

						playModule(moduledata.pop(), moduledata);
						console.log("remaining Modules: ", [...moduledata]);
					}
				}
				return count;
			});
		}, 1000);
	};

	const sessionComplete = () => {
		axios
			.post(
				`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/completesession`,
				{
					sessionId,
				},
				{
					headers: {
						Authorization: localStorage.getItem("access-token"),
					},
				}
			)
			.then((res) => {
				console.log(res);
			})
			.catch((e) => console.log(new Error(e.message)));
	};

	const playModule = async (module, moduledata) => {
		if (moduledata.length === 0) {
			startTheEnd(module);
		}
		try {
			console.log("playing", module);
			switch (module.type) {
				case "room":
					setCurrentModule({type:module.name});
					break;
				case "Transition":
					let playThis = transitionText[Math.floor(Math.random() * 5)];
					setCurrentModule({type:module.type, payload:playThis});
					await playAudioFromBase64(playThis);
					break;
				case "Welcome":
					setCurrentModule({type:module.type});
					await playAudioFromBase64(
						`${nickname} meet ${matchedUser}. Please introduce yourself to ${
							matchedUser.length > 0
								? matchedUser
								: "your partner."
						}`
					).catch((e) => console.log(e));
					break;
				case "Audio":
				case "Generic":
					var screens = module.screens;
					for (let screen of screens) {
						setCurrentModule({type:module.type, payload:screen});
						await playAudioFromBase64(screen.text);
					}
					break;
				case "Answers":
					// Show instructional screens before showing Answers, using screens attached to Questions module
					var screens = module.screens;
					for (let screen of screens) {
						setCurrentModule({type:"Generic", payload:screen});
						await playAudioFromBase64(screen.text);
					}
					setCurrentModule({type:module.type, payload:module.screens});
					await playAudioFromBase64("Here are your selections.");
					break;
				case "Role-play":
					var screens = module.screens;
					for (let screen of screens) {
						setCurrentModule({type:module.type, payload:{screen}});
						await playAudioFromBase64(screen.text);
					}
					setCurrentModule({type:module.type, payload:{
						character: {
							brief: module.characterBrief,
							scenario: module.scenario,
						},
					}});
					break;
				default:
					break;
			}
		} catch (e) {
			console.log(e);
		}
	};

	const startTheEnd = (module) => {
		setTotalElapsedTime((count) => {
			let timeoutDuration =
				(module.duration - (count - module.startAt)) * 1000;
			console.log("timeout set", timeoutDuration);
			timeoutRef.current = setTimeout(() => {
				setCurrentModule({type:"End"});
				setCurrentModuleTime(() => {
					setNextModuleTime(module.duration + module.startAt + 60);
					return module.duration + module.startAt;
				});
				console.log("Current time: " + currentModuleTime);
				console.log("Next time: " + nextModuleTime);
				sessionComplete();
				playAudioFromBase64(
					"Congrats on closing this portal, you two! Share with your partner something you want to acknowledge them for."
				);
				setTimeout(async () => {
					await leaveCall().catch((e) =>
						console.log(new Error(e.message))
					);
				}, 60000);
			}, timeoutDuration);
			return count;
		});
	};

	const playAudioFromBase64 = async (sentence) => {
		var token = localStorage.getItem("access-token");

		const res = await axios.post(
			`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getaudio`,
			{ sentence },
			{
				headers: {
					Authorization: token,
				},
			}
		);

		const arr = Base64.toUint8Array(res.data);

		const blob = new Blob([arr], {
			type: "audio/mpeg",
		});

		const audioObjectURL = window.URL.createObjectURL(blob);

		// Insert audio into the DOM (Safari Compatibility)
		const audioElement = document.createElement("audio");
		audioElement.setAttribute("id", "speakAudio");
		audioElement.setAttribute("controls", false);
		audioElement.setAttribute("style", "display:none");
		document.body.appendChild(audioElement);
		const sourceElement = document.createElement("source");
		audioElement.appendChild(sourceElement);
		sourceElement.src = audioObjectURL;
		sourceElement.type = "audio/mp3";

		const audio = new Audio(audioObjectURL);
		return new Promise((res, rej) => {
			audio.play().catch(() => {
				setTimeout(() => {
					res();
				}, 3000);
			});
			client.localTracks?.forEach((track) => {
				track.setMuted(true);
			});
			audio.onloadedmetadata = function () {
				// client.unpublish();
				setIsMuted(true);
				// duration in seconds
				// call functions here
			};
			audio.onended = function () {
				// client.publish(tracks);
				client.localTracks?.forEach((track) => {
					track.setMuted(false);
				});
				setIsMuted(false);
				res();
			};
		});
	};

	// const calcColorFromPercentage = (progress) => {
	// 	console.log(progress);
	// 	if (progress <= 70) {
	// 		return "primary";
	// 	} else if (progress > 70 && progress <= 90) {
	// 		return "warning";
	// 	} else {
	// 		return "error";
	// 	}
	// };
	const calcColorFromSecs = (remainingTime) => {
		console.log("Remaining: ", remainingTime + " = " + nextModuleTime + " - " + currentModuleTime + " - " + totalElapsedTime);
		if (remainingTime === 30) {
			firstBell.play();
		}
		else if (remainingTime === 10) {
			secondBell.play();
		}
		if (remainingTime > 30) {
			return "success";
		} else if (remainingTime <= 30 && remainingTime > 10) {
			return "inherit";
		} else {
			return "warning";
		}
	};

	const loadCurrentModule = useMemo(() => {
		if(currentModule === null) {
			return <div />;
		}
		const shouldGoFirst = (nickname < matchedUser); // "person with longer hair goes first"
		switch (currentModule.type) {
			case "Welcome":
				return <WelcomeModule matchedUser={matchedUser} />;
			case "Transition":
				return <TransitionModule payload={currentModule.payload} />;
			case "Answers":
				return <QuestionDiscussionModule payload={currentModule.payload} />;
			case "Audio":
				return <AudioModule client={client} shouldGoFirst={shouldGoFirst} payload={modulePayload} />;
			case "bad_startup_ideas":
				return <StartupIdeaModule payload={currentModule.payload} />;
			case "Role-play":
				return <RolePlayModule payload={currentModule.payload} />;
			case "experience":
				return <ExperienceModule payload={currentModule.payload} />;
			case "Generic":
				return <GenericModule payload={currentModule.payload} />;
			case "End":
				return (
					<div>
						Congrats on closing this portal, you two!
						Share with your partner something you want to acknowledge them for.
					</div>
				);
		}
	}, [currentModule]);

	return (
		<div className="module-player">
			{currentModule ? (
				<div
					className="module-section-container"
					style={{ minHeight: "00px", minWidth: "600px" }}
				>
					<div className="screen">
						{loadCurrentModule}
						<div
							style={{
								position: "fixed",
								top: "100px",
								left: "0px",
								height: "max-content",
								width: "100%",
							}}
						>
							<StyledLinearProgress
								variant="determinate"
								value={
									100 *
									(totalElapsedTime < nextModuleTime
										? (totalElapsedTime - currentModuleTime) /
										  (nextModuleTime - currentModuleTime)
										: 0)
								}
								color={calcColorFromSecs(
									nextModuleTime - currentModuleTime - totalElapsedTime
								)}
							/>
						</div>
						<div className="left-point" />
						<div className="room-carla carla-box">
							<img src="/assets/carla.gif" alt="carla" />
						</div>
					</div>
				</div>
			) : (
				<div className="screen">
					Starting Session <br /> Please wait...
				</div>
			)}
			{!modulesLoaded && (
				<video
					style={{
						position: "fixed",
						zIndex: "100",
						top: "0px",
						left: "0px",
						height: "100%",
						width: "100%",
						background: "black",
					}}
					src="/assets/countdown.mp4"
					alt="countdown"
					ref={videoRef}
					muted
				></video>
			)}
			<PartnerLeftDialog
				open={showDialog}
				closeDialog={() => setShowDialog(false)}
			></PartnerLeftDialog>
		</div>
	);
};

export default ModulePlayer;
