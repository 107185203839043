import React from "react";

const WelcomeModule = ({ matchedUser }) => {
	return (
		<div
			style={{
				display: "grid",
				placeItems: "center",
				textAlign: "center",
			}}
		>
			<div>Welcome to your session</div>
			<div>Meet your partner: {matchedUser}</div>
		</div>
	);
};

export default WelcomeModule;
