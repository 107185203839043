import React, { useEffect } from "react";

import LoginForm from "../LoginForm/LoginForm.jsx";
import "./Homepage.css";
const Homepage = (props) => {
	// useEffect(() => {
	// 	console.log(props.location.state);
	// });
	return (
		<div className="homepage-container">
			<div className="homepage">
				<LoginForm
					sId={
						props.location.state
							? props.location.state.sessionId
							: null
					}
				/>
			</div>
		</div>
	);
};
export default Homepage;
