import React from "react";

import AgoraRTC from "agora-rtc-sdk-ng";

const AudioModule = ({ client, shouldGoFirst, payload }) => {

	async function init() {
		const audioFileTrack = await AgoraRTC.createBufferSourceAudioTrack({
			source: "/assets/twistmas/12-days.mp3",
		});
		console.log("create audio file track success");
		
		// Start processing the audio data from the audio file
		audioFileTrack.setVolume(30);
		audioFileTrack.startProcessAudioBuffer();
		await client.publish([audioFileTrack]);

		client.on("source-state-change", async (currentState) => {
			if(currentState === "stopped") {
				console.log("Done playing");
				await client.unpublish(audioFileTrack);
				audioFileTrack.stopProcessAudioBuffer();
			}
		});
	}

	init();

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
				width: "100%",
				flexDirection: "column",
				fontSize: "24px",
			}}
		>
			<div
				className="dangerous-div"
				dangerouslySetInnerHTML={{ __html: payload?.text }}
			></div>
			<iframe src="https://curlyco.com/multitudes/lyrics.html" />
		</div>
	);
};

export default AudioModule;
