import PitchShift from "soundbank-pitch-shift";
import * as Tone from 'tone';
import Tuna from "tunajs";


export const chipmunkVoiceFilter = (payload) => {
	const { ctx, mic, destination } = payload;
	var gain = ctx.createGain();
	gain.gain.value = 2;

	const pitchShift = new Tone.PitchShift().connect(gain);
	mic.connect(pitchShift);

	pitchShift.windowSize = 0.08; // less echoic than default (0.1)
	pitchShift.pitch = 8.0;

	gain.connect(destination);
}

export const phaserVoiceFilter = (payload) => {
	const { ctx, mic, destination } = payload;
	var rate = 8;
	var depth = 0.3;
	var feedback = 0.9;
	var stereoPhase = 100;
	var baseModFreq = 500;

	let tuna = new Tuna(ctx);
	let effect = new tuna.Phaser({
		rate: rate,                            //0.01 to 8 is a decent range, but higher values are possible
		depth: depth,               	       //0 to 1
		feedback: feedback,      	           //0 to 1+
		stereoPhase: stereoPhase,              //0 to 180
		baseModulationFrequency: baseModFreq,  //500 to 1500
		bypass: 0, // if 1, disables the effect
	});
	mic.connect(effect.input);
	effect.connect(destination);  
} 

export const vibratoVoiceFilter = (payload) => {
	const { ctx, mic, destination } = payload;
	var freq = 9.0;

	// Delay
	let delayNode = ctx.createDelay();
	delayNode.delayTime.value = 0.03;

	let osc = ctx.createOscillator();
	let gain = ctx.createGain();
	let wetGain = ctx.createGain();

	gain.gain.value = 0.002; // depth of change to the delay:

	osc.type = 'sine';
	osc.frequency.value = freq;

	osc.connect( gain );
	gain.connect( delayNode.delayTime );
	mic.connect( delayNode );
	delayNode.connect( wetGain );
	wetGain.connect( destination );

	osc.start(0);
}

export const deepVoiceFilter = (payload) => {
	const { ctx, mic, destination } = payload;
	let pitchShift = PitchShift(ctx);
	var gain = ctx.createGain();
	gain.gain.value = 3;
	pitchShift.transpose = -4;
	pitchShift.wet.value = 2;
	pitchShift.dry.value = 0;

	mic.connect(pitchShift);
	pitchShift.connect(gain);
	gain.connect(destination);
};

export const alienVoiceFilter = (payload) => {
	const { ctx, mic, destination } = payload;
	let oscillator = ctx.createOscillator();
	oscillator.frequency.value = 3;
	oscillator.type = "sine";

	let oscillatorGain = ctx.createGain();
	oscillatorGain.gain.value = 0.05;

	let delay = ctx.createDelay();
	delay.delayTime.value = 0.05;

	mic.connect(delay);

	oscillator.connect(oscillatorGain);
	oscillatorGain.connect(delay.delayTime);
	delay.connect(destination);

	oscillator.start();
};

export const heliumVoiceFilter = (payload) => {
	const { ctx, mic, destination } = payload;
	let pitchShift = PitchShift(ctx);
	let gain = ctx.createGain();
	gain.gain.value = 2;
	pitchShift.transpose = 4;
	pitchShift.dry.value = 0;
	pitchShift.wet.value = 2;

	mic.connect(pitchShift);
	pitchShift.connect(gain);
	gain.connect(destination);
};

export const baneVoiceFilter = (payload) => {
	const { ctx, mic, destination } = payload;
	let waveShaper = ctx.createWaveShaper();
	waveShaper.curve = makeDistortionCurve(50);

	function makeDistortionCurve(amount) {
		var k = typeof amount === "number" ? amount : 50;
		var n_samples = 44100;
		var curve = new Float32Array(n_samples);
		var deg = Math.PI / 180;
		var x;
		for (let i = 0; i < n_samples; ++i) {
			x = (i * 2) / n_samples - 1;
			curve[i] = ((3 + k) * x * 20 * deg) / (Math.PI + k * Math.abs(x));
		}
		return curve;
	}

	// Pitch
	let deeper = new PitchShift(ctx);
	deeper.transpose = -3.5;
	deeper.wet.value = 2;
	deeper.dry.value = 0.5;

	// Telephone
	let lpf1 = ctx.createBiquadFilter();
	lpf1.type = "lowpass";
	lpf1.frequency.value = 5000.0;
	let lpf2 = ctx.createBiquadFilter();
	lpf2.type = "lowpass";
	lpf2.frequency.value = 5000.0;
	let hpf1 = ctx.createBiquadFilter();
	hpf1.type = "highpass";
	hpf1.frequency.value = 100.0;
	let hpf2 = ctx.createBiquadFilter();
	hpf2.type = "highpass";
	hpf2.frequency.value = 100.0;
	let compressor = ctx.createDynamicsCompressor();
	lpf1.connect(lpf2);
	lpf2.connect(hpf1);
	hpf1.connect(hpf2);
	hpf2.connect(waveShaper);

	mic.connect(deeper);
	deeper.connect(lpf1);

	waveShaper.connect(compressor);

	let gain = ctx.createGain();
	gain.gain.value = 1.5;
	compressor.connect(gain);
	gain.connect(destination);
};
